import _ from 'lodash';
import moment from 'moment';
import { defineStore } from 'pinia';
// import _ from 'lodash';
// import moment from 'moment';
import api from '@/api/api';

export const useSchedule = defineStore('scheduleStore', {
  state: () => ({
    currentEvents: [],
  }),
  actions: {
    async fetchEvents(config) {
      const { type, firstDate } = config;

      const fetchMethod = type === 'concert' ? 'getConcertEvents' : 'getCulturalEvents';
      this.currentEvents = await api[fetchMethod]({
        startDate: moment(firstDate).startOf('month').format('YYYY-MM-DD'),
        endDate: moment(firstDate).endOf('month').format('YYYY-MM-DD'),
      });

      // 只保留不可承租的日期
      this.currentEvents.systemEvents = _.filter(this.currentEvents.systemEvents, (({ isOpen, title }) => isOpen === false && title !== ''));
    },
  },
});
