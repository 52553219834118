<template>
  <div>
    <div class="schedule-tab">
      <router-link class="schedule-tab-link" :class="{'is-active': selectedSite === 'cultural'}" to="?site=cultural">文化館</router-link>
      <router-link class="schedule-tab-link" :class="{'is-active': selectedSite === 'concert'}" to="?site=concert">表演廳</router-link>
    </div>

    <CalendarView
      :init-date="initDate"
      :schedule-label="currentEvents.systemEvents"
      :schedule-events="currentEvents.events"
      :schedule-dots="scheduleDots"
      :selected-date="selectedDate"
      @select-date="selectDateFromCalendar"
      @select-event="selectEventFromCalendar"
      @change-calendar="changeCalendar"
    >
      <template #footer>
        <div class="schedule-legend d-none-md-above">
          <div class="schedule-legend-item">
            <div class="dot dot-blue mt-2 mr-1"></div>為館定日期
            <!-- 活動日/已租用/休館日/保養日/專用檔期/審核中 -->
          </div>
          <div class="schedule-legend-item">
            <div class="dot mt-2 mr-1"></div>為已被承租
          </div>
        </div>
      </template>
    </CalendarView>

    <div class="schedule-panel">
      <!-- selected 日期 -->
      <div class="schedule-panel-header">
        {{ $moment(selectedDate).format("L") }}
        <span
          class="tag"
          :class="eventClassCreator(event)"
          v-for="event in selectedSystemEvents"
          :key="event.startDate"
          >{{ event.title }}</span
        >
      </div>

      <!-- 活動列表 -->
      <div class="schedule-panel-content">
        當天活動
        <ul v-if="selectedEvents.length > 0 || false">
          <li v-for="event in selectedEvents" :key="event.id">
            <CollapseItem>
              <template #title>{{ event.title }}</template>
              <template #content="{ initHeight }">
                <img :src="`${domain}${event.coverImage}`" alt="" @load="initHeight()" />
                {{ $moment(event.startDate).format("L") }}
                <span v-if="event.endDate">至 {{ $moment(event.endDate).format("L") }}</span>
                <div>{{ event.rentRange || event.area }}</div>
              </template>
            </CollapseItem>
          </li>
        </ul>
        <div v-else>本日無活動</div>
      </div>
    </div>

    <div class="btn-group mt-3 pt-3 pb-3" v-if="isLogin">
      <RouterLink
        to="/rent/cultural"
        class="btn btn-md btn-primary"
        v-if="selectedSite === 'cultural'"
        >文化館檔期申請</RouterLink
      >
      <RouterLink
        to="/rent/concert"
        class="btn btn-md btn-primary"
        v-if="selectedSite === 'concert'"
        >表演廳檔期申請</RouterLink
      >
    </div>

    <!-- 活動資訊 popover -->
    <Transition name="fade">
      <div
        class="schedule-bubble"
        v-if="bubbleInfo"
        v-click-outside="toggleBubble"
        :style="{
          top: `${bubblePos.y - 20}px`,
          left: `${bubblePos.x}px`,
        }"
      >
        <div class="schedule-bubble-image" v-show="!!bubbleInfo.coverImage">
          <img :src="bubbleInfo.coverImage" alt="" />
        </div>
        <div class="schedule-bubble-content">
          <!-- <div class="schedule-bubble-tag">{{ bubbleInfo.eventType }}</div> -->
          <div class="schedule-bubble-title">
            <a :href="bubbleInfo.eventLink" v-if="bubbleInfo.eventLink" target="_blank">{{
              bubbleInfo.title
            }}</a>
            <template v-else>{{ bubbleInfo.title }}</template>
          </div>
          <div class="schedule-bubble-meta">
            {{ $moment(bubbleInfo.startDate).format("L") }}
            <span v-if="bubbleInfo.endDate">至 {{ $moment(bubbleInfo.endDate).format("L") }}</span>
          </div>
          <div class="schedule-bubble-meta">
            {{ selectedSite === "cultural" ? "文化館 " : "表演廳 " }}
            {{ bubbleInfo.rentRange || bubbleInfo.area }}
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapState } from 'pinia';
import { useSchedule } from '@/store/schedule';
import { useUserData } from '@/store/user';
import CalendarView from '@/components/CalendarView.vue';
import CollapseItem from '@/components/CollapseItem.vue';

export default {
  components: {
    CalendarView,
    CollapseItem,
  },
  data() {
    return {
      initDate: this.todayDateObj(), // 初始日期
      currentFirstDate: this.$moment().startOf('month').toDate(), // 當前月第一日
      selectedSite: 'cultural',
      selectedDate: this.todayDateObj(),
      bubbleInfo: null,
      bubblePos: {
        x: 0,
        y: 0,
      },
    };
  },
  methods: {
    selectDateFromCalendar(dateObj) {
      this.selectedDate = dateObj;
    },
    selectEventFromCalendar(eventObj, windowEvent) {
      const { originalItem: event } = eventObj;
      const { pageX: x, pageY: y } = windowEvent;
      this.toggleBubble(event, { x, y });
    },
    toggleBubble(eventObj, pos) {
      this.bubbleInfo = eventObj || null;
      this.bubblePos = pos || { x: 0, y: 0 };
    },
    eventClassCreator(eventObj) {
      return eventObj.type === 1 ? ['tag-orange'] : ['tag-blue'];
    },
    changeCalendar(dateObj) {
      this.fetchEvents({
        type: this.selectedSite,
        firstDate: dateObj,
      });
      this.currentFirstDate = dateObj;
    },
    ...mapActions(useSchedule, ['fetchEvents']),
  },
  computed: {
    selectedSystemEvents() {
      const selectedDate = this.selectedDate.getTime();
      return _.filter(this.currentEvents.systemEvents, ({ startDate }) => this.$moment(startDate).isSame(selectedDate, 'day'));
    },
    selectedEvents() {
      const selectedDate = this.selectedDate.getTime();
      // isBetween 參數 https://momentjs.com/docs/#/query/is-between/
      // start, end, 判斷單位, 頭尾包含
      // 預設不含頭尾'()' 改用含頭尾'[]'
      return _.filter(this.currentEvents.events, ({ startDate, endDate }) => this.$moment(selectedDate).isBetween(startDate, endDate, 'day', '[]'));
    },
    scheduleDots() {
      const datesHasSystemEvent = _.map(this.currentEvents.systemEvents, ({ startDate }) => ({ startDate, type: 1 }));
      const datesHasEvent = _.chain(this.currentEvents.events)
        .map(({ startDate, endDate }) => _.map(this.dateDurationToArray(startDate, endDate), (item) => ({ ...item, type: 3 })))
        .flatMap()
        .uniqBy('startDate')
        .value();

      const totalEvents = _.chain([...datesHasEvent, ...datesHasSystemEvent])
        .reduce((result, { startDate, type }) => {
          if (!result[startDate]) result[startDate] = [];
          result[startDate].push(type);
          return result;
        }, {})
        .toPairs()
        .map((item) => ({
          startDate: item[0],
          title: _.find(this.currentEvents.systemEvents, { startDate: item[0] })?.title || '',
          type: _.sortBy(item[1]),
        }))
        .value();

      return totalEvents;
    },
    domain() {
      return process.env.VUE_APP_DOMAIN;
    },
    ...mapState(useSchedule, ['currentEvents']),
    ...mapState(useUserData, ['isLogin']),
  },
  watch: {
    selectedSite(newValue) {
      this.fetchEvents({
        type: newValue,
        firstDate: this.currentFirstDate,
      });
    },
    '$route.query.site': {
      immediate: true,
      handler(siteName) {
        this.selectedSite = siteName || 'cultural';
      },
    },
  },
};
</script>
